import { VideoComponent } from '@vanguard/shared/components/video-component/video-component.component';
import { CreatedRoomEvent } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/events/created-room.interface';
import { UserLeftRoomEvent } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/events/user-left-room.interface';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { Stream, StreamTypes } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/entities/stream.interface';
import { VideoRoom } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/entities/room.interface';
import { VideoRoomPlugin } from '@vanguard/shared/services/janus/plugins/videoroom';
import { JanusAPI } from '@vanguard/shared/services/janus';
import { VideoRoomEvents } from '@vanguard/shared/services/janus/plugins/videoroom/enums/video-room-events.enum';
import { UserJoinedRoomEvent } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/events/user-joined-room.interface';
import { ParticipantLeftRoomEvent } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/events/participant-left-room.interface';
import { ParticipantJoinedRoomEvent } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/events/participant-joined-room.interface';
import { DestroyedRoomEvent } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/events/destroyed-room.interface';
import { VideoConferenceOptions } from './video-conference-options.interface';
import { MessageReceivedEvent } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/events/message-received.interface';
import { NewMessage } from '@vanguard/shared/services/janus/plugins/videoroom/interfaces/parameters/new-message.interface';
import { VideoConferenceService } from './video-conference.service';
import { VideoConferenceUser } from './video-conference-user.interface';
import { VideoConferencerRoom } from './video-conference-room.interface';
import { VideoConferenceSignaling } from './video-conference-signaling.interface';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'video-conference',
    templateUrl: 'video-conference.component.html',
    styleUrls: ['video-conference.component.scss'],
})

export class VideoConferenceComponent implements OnInit, OnDestroy {
    @ViewChildren(VideoComponent) videoComponents = new QueryList<VideoComponent>();

    @Input() room: VideoConferencerRoom;

    @Input() user: VideoConferenceUser;

    @Input() options: VideoConferenceOptions;

    @Output() participantJoined: EventEmitter<ParticipantJoinedRoomEvent> = new EventEmitter<ParticipantJoinedRoomEvent>();

    @Output() participantLeft: EventEmitter<ParticipantLeftRoomEvent> = new EventEmitter<ParticipantLeftRoomEvent>();

    @Output() userLeft: EventEmitter<UserLeftRoomEvent> = new EventEmitter<UserLeftRoomEvent>();

    @Output() userJoined: EventEmitter<UserJoinedRoomEvent> = new EventEmitter<UserJoinedRoomEvent>();

    @Output() createdRoom: EventEmitter<CreatedRoomEvent> = new EventEmitter<CreatedRoomEvent>();

    @Output() destroyedRoom: EventEmitter<DestroyedRoomEvent> = new EventEmitter<DestroyedRoomEvent>();

    @Output() messageReceived: EventEmitter<MessageReceivedEvent> = new EventEmitter<MessageReceivedEvent>();

    @Output() offlineDetected: EventEmitter<Boolean> = new EventEmitter<Boolean>();

    @Output() unSuportedBrowser: EventEmitter<Boolean> = new EventEmitter<Boolean>();

    @Output() slowLinkRemoteStream: EventEmitter<Boolean> = new EventEmitter<Boolean>();

    @Output() slowLinkLocalStream: EventEmitter<Boolean> = new EventEmitter<Boolean>();

    @Output() customerOnline: EventEmitter<Boolean> = new EventEmitter<Boolean>();


    @Input() blurcam;

    private selectedAudioInput: string;

    public selectedVideoInput: string;

    public videoRoomPlugin: VideoRoomPlugin;

    private currentRoomId: number;

    private janusInstance: any;

    public videoConfInFocus = false;

    private hideControlTimerFunction;

    private isDeviceTouchBased = false;

    public videoInputDevices = {
        frontCamera: null,
        backCamera: null
    };

    public flipToFrontCamera = false;

    public flipToBackCamera = false;

    public streamTypes = StreamTypes;

    public primaryFocusStream: StreamTypes = StreamTypes.LOCAL;

    public recordingEnabledInRoom = false;

    public expandStats = false;

    public reconnecting = false;

    public browseName = "";

    public device = "";

    public verified: boolean = false;

    public verifiedMsg: any = "";

    public online: boolean = true;

    public onlineMsg: any = "";

    public remoteStreamBitRateZeroCount: number = 0;

    public remoteStremeBitRateThreshold: number = 2;
    public connecting: boolean = false;
    public signaling: VideoConferenceSignaling = {
        redStart: 0,
        greenStart: 125,
        amberStart: 65,
        redEnd: 64,
        greenEnd: 100000,
        amberEnd: 124
    };
    public remoteStreamBitRate: number;

    public minimizeScreen: boolean = false;

    public doMinimizeVideo: boolean = false;

    constructor(private videoConferenceService: VideoConferenceService) { }

    ngOnInit() {
        console.log(this.primaryFocusStream, 'primaryFocusStreamprimaryFocusStream123123', StreamTypes)
        this.initialCheck();
    }
    async initialCheck() {
        await this.browserDetect();
        if (this.verified == true) {
            this.unSuportedBrowser.emit(false)
            this.initializeVideoConference().then();
            this.subscribeInternetCheck();
            this.subscribeFor0Bitrate()
        } else {
            this.unSuportedBrowser.emit(true)
        }
    }
    public async initializeVideoConference(): Promise<boolean> {
        try {
            const { janusService, janusInstance } = await this.videoConferenceService.createJanusInstance();
            this.janusInstance = janusInstance;

            // Get audio and video device list
            const mediaDevices = await janusService.getDevicesList();
            const audioInputs = mediaDevices.audioInputs;
            const videoInputs = mediaDevices.videoInputs;

            // If no video/audio inputs, then return
            if (videoInputs.length === 0 || audioInputs.length === 0) {
                return;
            }

            // For audio input, choose first input by default
            this.selectedAudioInput = audioInputs[0].deviceId;

            // For video input, check for front and back camera
            // Check for front camera
            const frontCamera = videoInputs.find(videoInput =>
                videoInput.label.toLowerCase().includes('front') ||
                videoInput.label.toLowerCase().includes('integrated'));
            if (frontCamera) {
                this.videoInputDevices.frontCamera = frontCamera.deviceId;
                // If present, then choose front camera as default input
                this.selectedVideoInput = this.videoInputDevices.frontCamera;
            }
            // Check for back camera
            const backCamera = videoInputs.find(videoInput => videoInput.label.toLowerCase().includes('back'));
            if (backCamera) {
                this.videoInputDevices.backCamera = backCamera.deviceId;
                if (!this.selectedVideoInput) {
                    // If front camera not present, choose back camera as default input
                    this.selectedVideoInput = this.videoInputDevices.backCamera;
                }
            }

            // Create instance of video room plugin and attach to janus
            this.videoRoomPlugin = await this.videoConferenceService.createVideoRoomPluginInstance(this.janusInstance);
            console.log(this.videoRoomPlugin, 'videoRoomPlugin')
            // Create event listeners
            this.createEventListeners();

            // Fetch all rooms
            // tslint:disable-next-line:max-line-length
            const matchingVideoRooms: Array<VideoRoom> = await this.videoConferenceService.getMatchingVideoRooms(this.room, this.videoRoomPlugin);

            // If there is an room already exist with the same name, then join in that room
            if (matchingVideoRooms.length > 0) {
                const matchingVideoRoom: VideoRoom = matchingVideoRooms[0];

                // Connect to the room
                await this.videoConferenceService.connectToRoom(this.user,
                    matchingVideoRoom.room,
                    this.selectedAudioInput,
                    this.selectedVideoInput,
                    {
                        enable: true,
                        timeIntervalInMs: 1000
                    },
                    {
                        enable: true,
                        timeIntervalInMs: 1000
                    },
                    this.videoRoomPlugin,
                    this.options.disableAudio,
                    this.options.disableVideo);
                // Set matching room id in current room
                this.currentRoomId = matchingVideoRoom.room;
                // Update recording enabled in room flag
                this.recordingEnabledInRoom = matchingVideoRoom.record || false;
                this.customerOnline.emit(true);
            }
            return true;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    private createEventListeners() {
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.CREATED_ROOM, (data: CreatedRoomEvent) => {
            this.createdRoom.emit(data);
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.DESTROYED_ROOM, (data: CreatedRoomEvent) => {
            this.destroyedRoom.emit(data);
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.PARTICIPANT_JOINED_ROOM, (data: ParticipantJoinedRoomEvent) => {
            this.primaryFocusStream = StreamTypes.REMOTE;
            this.participantJoined.emit(data);
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.PARTICIPANT_LEFT_ROOM, (data: ParticipantLeftRoomEvent) => {
            this.primaryFocusStream = StreamTypes.LOCAL;
            this.participantLeft.emit(data);
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.JOINED_ROOM, (data: UserJoinedRoomEvent) => {
            this.userJoined.emit(data);
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.LEFT_ROOM, (data: UserLeftRoomEvent) => {
            if (!this.reconnecting) {
                this.userLeft.emit(data);
            }
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.MESSAGE_RECEIVED, (data: MessageReceivedEvent) => {
            this.messageReceived.emit(data);
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.CONNECTION_FAILED, (data: MessageReceivedEvent) => {
            this.reconnect();
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.SLOW_LINK_LOCAL_STREAM, (data: any) => {
            this.slowLinkRemoteStream.emit(data);
        });
        this.videoRoomPlugin.addEventListener(VideoRoomEvents.SLOW_LINK_REMOTE_STREAM, (data: any) => {
            this.slowLinkLocalStream.emit(data);
        });
    }

    ngOnDestroy() {
        this.destroyVideoConference();
    }

    private async reconnect() {
        this.reconnecting = true;
        this.destroyVideoConference();
        await this.initializeVideoConference();
        this.reconnecting = false;
    }

    private destroyVideoConference() {
        // Diconnect local stream
        this.disconnect();

        // Destroy video room plugin
        this.videoConferenceService.destroyVideoRoomPlugin(this.videoRoomPlugin);

        // Destroy Janus
        this.videoConferenceService.destroyJanus(this.janusInstance);
    }

    public disconnect() {
        if (this.videoRoomPlugin && this.videoRoomPlugin.localStream) {
            this.videoRoomPlugin.localStream.disconnect();
        }
    }

    public toggleAudio(stream: Stream) {
        stream.toggleAudio();
    }

    public toggleVideo(stream: Stream) {
        stream.toggleVideo();
    }

    public toggleCamera() {
        if (this.flipToBackCamera || this.flipToFrontCamera) {
            return;
        }
        // Check if both front and camera are available
        if (this.videoInputDevices.backCamera &&
            this.videoInputDevices.frontCamera) {
            // If front camera is selected, switch to back camera
            if (this.selectedVideoInput === this.videoInputDevices.frontCamera) {
                this.flipToBackCamera = true;
                setTimeout(() => {
                    // Change stream video input to back camera
                    this.selectedVideoInput = this.videoInputDevices.backCamera;
                    this.videoRoomPlugin.localStream.changeVideoInputDevice(
                        this.selectedVideoInput
                    );
                }, 500);
                setTimeout(() => {
                    this.flipToBackCamera = false;
                }, 3000);
            } else {
                // Change stream video input to front camera
                this.flipToFrontCamera = true;
                setTimeout(() => {
                    this.selectedVideoInput = this.videoInputDevices.frontCamera;
                    this.videoRoomPlugin.localStream.changeVideoInputDevice(
                        this.selectedVideoInput
                    );
                }, 500);
                setTimeout(() => {
                    this.flipToFrontCamera = false;
                }, 3000);
            }
        }
    }

    public handleMouseEnter() {
        if (this.isDeviceTouchBased) {
            return;
        }
        this.videoConfInFocus = true;
        if (this.hideControlTimerFunction) {
            clearTimeout(this.hideControlTimerFunction);
        }
    }

    public handleMouseLeave() {
        this.hideControlTimerFunction = setTimeout(() => {
            this.videoConfInFocus = false;
        }, 5000);
    }

    public handleTouchStart() {
        this.isDeviceTouchBased = true;
        this.videoConfInFocus = true;
        if (this.hideControlTimerFunction) {
            clearTimeout(this.hideControlTimerFunction);
        }
        this.hideControlTimerFunction = setTimeout(() => {
            this.videoConfInFocus = false;
        }, 5000);
    }

    public takeLocalStreamSnapshot() {
        // Get local stream video component
        const localVideoComponent = this.videoComponents.find(videoCompnent => videoCompnent.type === StreamTypes.LOCAL);
        if (localVideoComponent) {
            return localVideoComponent.takeSnapshot();
        }
        return false;
    }

    public minimize() {
        this.minimizeScreen = !this.minimizeScreen;
        this.doMinimizeVideo = true;
    }

    public takeRemoteStreamSnapshot() {
        // Get remote stream video component
        const remoteVideoComponent = this.videoComponents.find(videoCompnent => videoCompnent.type === StreamTypes.REMOTE);
        if (remoteVideoComponent) {
            return remoteVideoComponent.takeSnapshot();
        }
        return false;
    }

    public showLocalStreamGuideBox(widthRatioToHeight: number) {
        // Get local stream video component
        const localVideoComponent = this.videoComponents.find(videoCompnent => videoCompnent.type === StreamTypes.LOCAL);
        if (localVideoComponent) {
            if (this.primaryFocusStream !== StreamTypes.LOCAL) {
                this.primaryFocusStream = StreamTypes.LOCAL;
                setTimeout(() => {
                    localVideoComponent.showGuideBox(widthRatioToHeight);
                }, 5000);
            } else {
                localVideoComponent.showGuideBox(widthRatioToHeight);
            }
        }
    }

    public hideLocalStreamGuideBox() {
        // Get local stream video component
        const localVideoComponent = this.videoComponents.find(videoCompnent => videoCompnent.type === StreamTypes.LOCAL);
        if (localVideoComponent) {
            localVideoComponent.hideGuideBox();
        }
    }

    public async sendMessage(message: NewMessage) {
        await this.videoRoomPlugin.localStream.sendMessage(message);
    }

    private setBitRateLimit(newBitRate: number) {
        this.videoRoomPlugin.localStream.setBitrateLimit(newBitRate);
    }
    public async updateRoom() {
        if (this.videoRoomPlugin && this.videoRoomPlugin.localStream) {
            this.options.disableAudio = false;
            this.options.disableVideo = false;
            this.connecting = true;
            this.destroyVideoConference();
            await this.initializeVideoConference();
            this.connecting = false;
        }
    }

    browserDetect() {
        //Detect the Browser
        return new Promise<any>((resolve, reject) => {
            let browser = (function () {
                var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
                switch (true) {
                    case test(/edg/i): return "Microsoft Edge";
                    case test(/trident/i): return "Microsoft Internet Explorer";
                    case test(/firefox/i): return "Mozilla Firefox";
                    case test(/fxios/i): return "Mozilla Firefox IOS";
                    case test(/opr\//i): return "Opera";
                    case test(/ucbrowser/i): return "UC Browser";
                    case test(/samsungbrowser/i): return "Samsung Browser";
                    case test(/miui/i): return "MI Browser";
                    case test(/chrome|chromium/i): return "Google Chrome";
                    case test(/crios/i): return "Google Chrome IOS";
                    case test(/safari/i): return "Apple Safari";
                    default: return "Other";
                }
            })();
            let device = this.getMobileOperatingSystem()
            console.log("device", device)
            this.browseName = browser;
            this.device = device;
            console.log("browseName", browser, this.browseName, this.device)
            if (this.device == 'iOS' && this.browseName == "Apple Safari") {
                this.verified = true;
                resolve(true)
            } else if (this.device == 'Android' && this.browseName == "Google Chrome") {
                this.verified = true;
                resolve(true)
            } else if (this.device == 'other' && this.browseName == "Google Chrome") {
                this.verified = true;
                resolve(true)
            } else if (this.device == 'Windows Phone' && this.browseName == "Google Chrome") {
                this.verified = true;
                resolve(true)
            } else {
                this.verified = false;
                this.verifiedMsg = `Current Browser: ${this.browseName} not supported.
            \n Kindly connect with below browsers and device.
            \n Desktop: Windows/MAC Browser: Google Chrome
            \n Mobile: Android Browser: Google Chrome, Mobile: iOS Browser: Safari`;
                resolve(true)
            }
        })
    }
    /**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
    getMobileOperatingSystem() {
        //Detect the Mobile OperatingSystem
        let userAgent = navigator.userAgent || navigator.vendor;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
            return "iOS";
        }

        return "other";
    }
    public subscribeInternetCheck() {
        //Internet Detection
        window.ononline = () => {
            console.log("online");
            if (this.online == false) {
                console.log("Going online");
                setTimeout(() => {
                    this.offlineDetected.emit(false)
                    this.onlineMsg = "Internet Is Back! Hold on while we connect you back."
                    console.log("Connecting To Room");
                    this.reconnect();
                }, 2000);
            }
            this.online = true;
        }
        window.onoffline = () => {
            console.log("offline");
            console.log('came offline')
            this.online = false;
            this.offlineDetected.emit(true)
            this.onlineMsg = "No Internet Detected! Kindly check your internet connection."
        }
    }
    public reconnectStream() {
        this.reconnect();
    }
    public subscribeFor0Bitrate() {
        console.log("IN subscribeFor0Bitrate")
        setTimeout(() => {
            if (this.videoRoomPlugin && this.videoRoomPlugin.remoteStreams && this.videoRoomPlugin.remoteStreams.length > 0) {
                console.log("subscribeFor0Bitrate", this.videoRoomPlugin.remoteStreams[0].bitRate, this.videoRoomPlugin.remoteStreams);
                let bitRate = this.videoRoomPlugin.remoteStreams[0].bitRate;
                if (bitRate != null && bitRate != undefined) {
                    let bitRateArrr = bitRate.toString().split(" ");
                    let bitRateVal = parseInt(bitRateArrr[0]);
                    this.remoteStreamBitRate = bitRateVal;
                    if (bitRateVal == 0) {
                        this.remoteStreamBitRateZeroCount = this.remoteStreamBitRateZeroCount + 1;
                    } else {
                        this.remoteStreamBitRateZeroCount = 0;
                    }
                } else {
                    this.remoteStreamBitRateZeroCount = 0;
                }
            }
            this.subscribeFor0Bitrate()
        }, 2000)
    }

    async enableRecording(status){
        const recordingStatus : VideoRoom = await this.videoConferenceService.enableRecording(this.room, this.videoRoomPlugin,status);
        console.log("recordingStatus",recordingStatus)
    }
}
