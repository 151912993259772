import { Injectable } from "@angular/core";
import { BackstageServiceComponent } from '@vanguard/shared/services/backstage.service';
import { BackendService } from '@vanguard/shared/services/backend.service';
import { CommonhttpService } from '@vanguard/shared/services/commonhttp.service';
import { environment as configs } from 'environments/environment';
import { map } from 'rxjs/operators';
import { UserService } from '@vanguard/shared/services/user.service';

@Injectable()
export class GoogleRecaptchaService {
    public siteKey = "";
    public captchaDetail: any = [];
    public captchaEnabled: Boolean = false;
    public captchaType: string;
    public captchaResponse: string;
    baseURL: string = configs.clientUrl;    

    constructor(private backendService: BackendService,
        public backstageService: BackstageServiceComponent,
        private userService: UserService,
        private commonApiService?: CommonhttpService) {
    }

    async fetchSiteKey(){
        return new Promise((resolve) => {
            this.backendService.getSetupConfigDetails('Analytics', 'Google Recaptcha').subscribe(response => {
                if (response) {
                    if(configs.encryptRequest == true){
                        let data = this.commonApiService.decryptDataV2(response)
                        resolve(data[0].siteKey);
                    }else{
                        resolve(response[0].siteKey);
                    }
                } else {
                    resolve(null);
                }
            });
        });
    }

    verifyCaptcha(application_id, response) {
        const data = {
            "application_id": application_id,
            "captchaResponse": response
          };
          const body = JSON.stringify(data);
        return this.commonApiService.post(`${this.baseURL}/v3/captcha/validateCaptcha`, body)
      .pipe(map((response: Response) => response));
    } // public verifyCaptcha(captchaResponse)
}